import * as React from "react"
import { FunctionComponent } from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NotFoundPage {}

const NotFoundPage: FunctionComponent<NotFoundPage> = () => {
  return (
    <Layout showSearchbox={true} useH1Header={false}>
      <Seo />
      <div>
        <h1>Not found!</h1>
      </div>
    </Layout>
  )
}

export default NotFoundPage
